import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const MyButton = styled(Button)({
  background: '#3D8D18',
  border: 0,
  borderRadius: 3,
  color: 'white',
  height: 48,
  padding: '0 30px',
});

export default function CustomButton(props) {
  return <MyButton fullWidth onClick={props.onClick}>{props.title}</MyButton>;
}
const menuText = [
    { title: 'Vegetarisch',
      dishes: [
        'Rijst',
        'Dew bhojan',
        'Kharie',
        'Daal',
        'Kouseband + aardappelen',
        'Pompoen (Khora)',
        'Spits kool',
        'Aubergine (Bhatta)',
        'Seem Aloe',
        'Daal  Roti en Aloe Roti',
        'Tjatnie (Sambal)',
        'Vegetarisch Gele , Bruine, Witte Nasi',
    ]},
    { title: 'Niet - vegetarisch',
      dishes: [
        'Chinese mie met Kipfilet', 
        'Bruine Nasi en witte Nasi   met Kipfilet en Garnalen',
        'Gele nasi met Garnalen',
        'Pom met rijst',
        'Roti met  Kip /Doks/ Schapen vlees',
        'Vegetarisch Soja, Tempe,  En Tahoe  en Panir met Groente', 
        'Groente mix  Schotels',
        'Vlees Gerechten Kip    in kerrie en Ketjap   Doksa en schapen',
        'Vlees in kerrie',
        'Vis Gerechten in kerrie en tomaten saus',
        'Garnalen in kerrie en tomaten saus',  
    ]},
    { title: 'Hapjes',
      dishes: [
        'Sate met Pindasaus',
        'Saoto Soep',
        'Garnalen',
        'Loempia',
        'Samosa',
        'Bara',
        'Cassave',
        'Ghoegrie',
        'Djilibie',
        'Bojo',
        'Telo',
    ]},
]

export default menuText;
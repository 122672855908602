/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './paragraph.css';
import './paragraphmobile.css'
import { isMobile } from 'react-device-detect';

export default class Paragraph extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const {title, text} = this.props;
        return (
          <div className={isMobile ? "ContainerMobile" : "Container"}>
            <text className={isMobile ? "TitleMobile" : "Title"}>{title}</text>
            <text className={isMobile ? "TextMobile" : "Text"}>{text}</text>
          </div>
        )
    }
}

import React, { Component } from 'react';
import './label.css';

export default class Label extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }
    render() {
        const { title, label} = this.props;
        return (
          <div className="LabelContainer">
            <text className="LabelTitle">{title}</text>
						<text className="Label">{label}</text>
          </div>
        )
    }
}

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './App.css';
import { ScrollTo } from "react-scroll-to";
import * as firebase from 'firebase';
import emailjs from 'emailjs-com';
import { isMobile, isBrowser, isTablet} from 'react-device-detect';
import colors from './resources/colors';
import text from './resources/text';
import menuText from './resources/menutext';
import Paragraph from './components/paragraph';
import Label from './components/label';
import CustomButton from './components/button';
import CustomTextField from './components/textfield';
import Card from './components/card';
import photoFirst from './resources/images/1.png';
import photoSecond from './resources/images/2.png';
import photoThird from './resources/images/3.png';



export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      page: 1,
      name: "",
      email: "",
      message: "",
      error: {
        name: false,
        email: false,
        message: false
      },
    };
    this.updatePage = this.updatePage.bind(this);
    this.renderMenuBar = this.renderMenuBar.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.isValid = this.isValid.bind(this);
    this.sendMail = this.sendMail.bind(this);
  }

  componentDidMount() {
    const firebaseConfig = {
      apiKey: "AIzaSyC7SpqCNaX756ZVQXAia5b9Mrsa6284Jhk",
      authDomain: "catering-63d35.firebaseapp.com",
      databaseURL: "https://catering-63d35.firebaseio.com",
      projectId: "catering-63d35",
      storageBucket: "catering-63d35.appspot.com",
      messagingSenderId: "636244158122",
      appId: "1:636244158122:web:ee9542bf0cee05ea9812d3",
      measurementId: "G-KCGKYH1XJH"

  };
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.analytics().logEvent('screen_view', {});
  }

  updatePage(number) {
    this.setState({ page: number});
  }

  handleChangeName(name) {
    this.setState(prevState => ({
      name: name, error: {...prevState.error, name: false}
    }))
  }

  handleChangeEmail(email) {
    this.setState(prevState => ({
      email: email, error: {...prevState.error, email: false}
    }))
  }

  handleChangeMessage(message) {
    this.setState(prevState => ({
      message: message, error: {...prevState.error, message: false}
    }))
  }

  sendMail() {
    if (this.isValid()) {
      emailjs.send('gmail', 'template_HIYkZdJF', {to_name: 'Kumar', from_name: this.state.name, reply_to: this.state.email , message_html: this.state.message}, 'user_FIsBp1qvntv5PuNcWcEGJ');
      this.setState({name: '', email: '', message: ''});
    } 
  }

  isValid() {
    let valid = true;
    const { name, email, message } = this.state;
    if (name === "") {
      valid = false;
      this.setState(prevState => ({
        error: {...prevState.error, name: true}
      }))
    }
    if (email === "") {
      valid = false;
      this.setState(prevState => ({
        error: {...prevState.error, email: true}
      }))
    }
    if (message === "") {
      valid = false;
      this.setState(prevState => ({
        error: {...prevState.error, message: true}
      }))
    }
    return valid;
  }

  renderMenuBar(isMobile) {
    const array = [{name: 'HOME', ref: this.homePage }, {name: 'MENU', ref: this.menuPage }, {name: 'OVER ONS', ref: this.about }, {name: 'CONTACT', ref: this.contact }];
    return array.map((item, index) => {
      return (
        <ScrollTo key={item.name}>
          {({ scrollTo }) => (
            <text className={isMobile ? "MenuButtonMobile" : "MenuButton"} onClick={() => this.setState({ page: index }, () => scrollTo({ ref: item.ref, x: 0, y: (window.innerHeight * this.state.page), smooth: true }))}>{item.name}</text>
          )}
        </ScrollTo>
      )
    })
  }

  renderMenu(isMobile) {
    return menuText.map(item => {
      return (
        <Card isMobile={isMobile} key={item.title} item={item} />
      )
    })
  }


  render() {
    console.log(this.state.error);
    if (isBrowser) {
      return (
        <div className="App">
          <div className="Header">
            <div className="LogoDiv">
              <text style={{color: colors.green}} className="logoText">Lekker </text>
              <text style={{color: colors.light_green}} className="logoText">& </text>
              <text style={{color: colors.green}} className="logoText">Lekker</text>
            </div>
            <div className="Menu">
              {this.renderMenuBar()}
            </div>
          </div>
          <div className="topDiv" />
          <div ref={this.homePage} className="HomePage">
            <div className="DivLeft">
              <Paragraph text={text.paragraph1} title="Catering op maat" />
            </div>
            <div className="DivRight">
              <img className="Image" src={photoFirst} alt="Logo" />
            </div>
          </div>
          <div ref={this.menuPage} className="MenuPage">
            {this.renderMenu()}
          </div>
          <div ref={this.about} className="About">
            <div className="DivRight">
              <img className="Image" src={photoSecond} alt="Logo" />
            </div>
            <div className="DivLeft">
              <Paragraph text={text.paragraph2} title="Over ons" />
            </div>
          </div>
          <div ref={this.contact} className="Contact">
            <div className="DivLeft">
              <div className="DivForm">
                <Label title="Telefoon" label="+31 6 14 87 77 15" />
                <Label title="Email" />
                <CustomTextField value={this.state.name} error={this.state.error.name} type="text" onChange={this.handleChangeName} placeholder="Naam" multiline={false} />
                <CustomTextField value={this.state.email} error={this.state.error.email} type="email" onChange={this.handleChangeEmail} placeholder="Email" multiline={false} />
                <CustomTextField value={this.state.message} error={this.state.error.message} onChange={this.handleChangeMessage} placeholder="Bericht" multiline />
                <CustomButton onClick={() => this.sendMail()} title="Verzenden" />
              </div>
            </div>
            <div className="DivRight">
              <img className="Image" src={photoThird} alt="Logo" />
            </div>
          </div>
        </div>
      );  
    }
    if (isMobile || isTablet) {
      return (
        <div className="App">
          <div className="HeaderMobile">
            <div className="LogoDiv">
              <text style={{color: colors.green}} className="logoTextMobile">Lekker </text>
              <text style={{color: colors.light_green}} className="logoTextMobile">& </text>
              <text style={{color: colors.green}} className="logoTextMobile">Lekker</text>
            </div>
            <div className="Menu">
              {this.renderMenuBar(isMobile)}
            </div>
          </div>
          <div ref={this.homePage} className="HomePageMobile">
            <div className="DivMobileTop">
              <img className="Image" src={photoFirst} alt="Logo" />
            </div>
            <div className="DivMobileBottom">
              <Paragraph isMobile text={text.paragraph1} title="Catering op maat" />
            </div>
          </div>
          <div ref={this.menuPage} className="MenuPageMobile">
            {this.renderMenu(isMobile)}
          </div>
          <div ref={this.about} className="AboutPageMobile">
            <div className="DivMobileTop">
              <img className="Image" src={photoSecond} alt="Logo" />
            </div>
            <div className="DivMobileBottom">
              <Paragraph isMobile text={text.paragraph2} title="Over ons" />
            </div>
          </div>
          <div ref={this.contact} className="ContactMobile">
            <div className="DivMobileTop">
              <img className="Image" src={photoThird} alt="Logo" />
            </div>
            <div className="DivMobileBottom">
              <div className="DivForm">
                <Label title="Telefoon" label="+31 6 14 87 77 15" />
                <Label title="Email" />
                <CustomTextField value={this.state.name} error={this.state.error.name} type="text" onChange={this.handleChangeName} placeholder="Naam" multiline={false} />
                <CustomTextField value={this.state.email} error={this.state.error.email} type="email" onChange={this.handleChangeEmail} placeholder="Email" multiline={false} />
                <CustomTextField value={this.state.message} error={this.state.error.message} onChange={this.handleChangeMessage} placeholder="Bericht" multiline />
                <CustomButton onClick={() => this.sendMail()} title="Verzenden" />
              </div>
            </div>
          </div>
        </div>
      );  
    }
  }
}
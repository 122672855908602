/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import './card.css';

export default class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.renderDishes = this.renderDishes.bind(this);
    }

    renderDishes(isMobile) {
      const {item} = this.props;
      return item.dishes.map(dish => {
        return (
          <text className={isMobile ? "DishTextMobile" : "DishText"} key={dish}>{dish}</text>
        )
      })
    }

    render() {
        const {item, isMobile} = this.props;
        return (
          <div className={isMobile ? "MenuCardContainerMobile" : "MenuCardContainer"}>
            <text className={isMobile ? "TitleTextMobile" : "TitleText"}>{item.title}</text>
            {this.renderDishes(isMobile)}
          </div>
        )
    }
}

import React from 'react';
import { styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const MyTextField = styled(TextField)({
  background: '#F6F9F5',
  marginBottom: 40,
  borderBottomColor: '#D4DCCE'
});

export default function CustomTextField(props) {
  const {disabled, placeholder, multiline, onChange, type, error, value} = props;
  return <MyTextField value={value} error={error} fullWidth type={type} onChange={event => onChange(event.target.value)} rows={5} rowsMax={5} multiline={multiline} placeholder={placeholder} disabled={disabled}>Styled Components</MyTextField>;
}